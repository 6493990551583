import * as React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { AnimatedLink } from "../gatsby-theme-amaranth/components/Links";
import { useConfig } from "gatsby-theme-advanced";

import Layout from "../gatsby-theme-amaranth/layouts";

const AboutPage = (): JSX.Element => {
  const config = useConfig();

  const Wrapper = styled.main`
  width: 100%;
  max-width: calc(1144px + 2 * 16px);
  word-wrap: break-word;
  word-break: break-word;
  display: grid;
  grid-gap: 24px;
  // justify-items: center;
  margin: 0 auto;
  padding: 0 1em;
`;

  return (
    <Layout>
      <div className="about-container">
        <Helmet title={`About | ${config.website.title}`} />

        <Wrapper>

          <h1>Contact</h1>

          <h3>Know of other inclusive cards? Are there details that need updating?</h3>

          <p>Email <AnimatedLink to="mailto:hello@inclusive.cards">hello@inclusive.cards</AnimatedLink> or use this form.</p>

          <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" action="/pages/contact-success">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <p>
              <label>Your Name: </label><input type="text" name="name" placeholder="e.g. Dominic Lambert" />
            </p>
            <p>
              <label>Your Email: </label><input type="email" name="email"  placeholder="e.g. email@example.com" />
            </p>
            <p>
              <label>Message: </label><textarea name="message" placeholder="Your message"></textarea>
            </p>
            <p>
              <button type="submit">Send</button>
            </p>
          </form>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default AboutPage;
